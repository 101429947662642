import React, { useCallback } from "react";
import journeys from "../static/data/journeys.js";
import amritsar from "../static/images/amritsar.jpg";
import kerela from "../static/images/kerela.jpg";
import kashmir from "../static/images/kashmir.jpg";
import ladakh from "../static/images/ladakh.jpg";
import punakha from "../static/images/product/punakha/punakha_intro.jpg";
import bentota from "../static/images/product/bentota/bentota_intro.jpg";
import colombo from "../static/images/product/colombo/colombo_intro.jpg";
import thimphu from "../static/images/product/thimphu/thimphu_intro.jpg";
import kandy from "../static/images/product/kandy/kandy_intro.jpg";
import paro from "../static/images/product/paro/paro_intro.jpg";

import "../sass/layout/_signatureJourneys.scss";
import "../sass/main.scss";
import { SecondCard } from "./journeyCard.js";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions.js";

const IMAGES = {
  kerela,
  kashmir,
  amritsar,
  ladakh,
  punakha,
  bentota,
  colombo,
  thimphu,
  kandy,
  paro,
};

export const SignatureJourneys = () => {
  const [currentCountry, setCurrentCountry] = React.useState("India");
  const [index, setIndex] = React.useState(0);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);

  const { width } = useWindowDimensions();

  const countryList = Object.values(journeys).map((country) => country.country);

  const [highlightedIndex, setHighlightedIndex] = React.useState(null);

  const renderCountryList = React.useMemo(
    () =>
      countryList.map((country, index) => (
        <li
          style={{ opacity: isOpen ? 1 : 0 }}
          onClick={() => {
            setCurrentCountry(country);
            setIndex(index);
          }}
          key={country}
        >
          {country}
        </li>
      )),
    [isOpen, countryList]
  );

  const renderCards = React.useMemo(() => {
    return journeys
      .at(index)
      .tours.slice(0, 4)
      .map((dest, ind) => {
        const shouldHighlight = highlightedIndex === ind;
        return (
          <SecondCard
            navToFunction={() => {
              navigate("/book", {
                state: {
                  country: currentCountry,
                  destination: dest.destination,
                },
              });
            }}
            shouldRenderEnquireButton={dest.attractions !== "Coming Soon"}
            navTo={`:${currentCountry}/:${dest.destination}`}
            key={ind}
            img={IMAGES[dest.img_filename]}
            dest={dest}
            shouldHighlight={shouldHighlight}
            index={ind}
            setHighlightedIndex={setHighlightedIndex}
          />
        );
      });
  }, [index, highlightedIndex, currentCountry, navigate]);

  const navToCountry = useCallback(
    () => navigate(`/:${currentCountry}`),
    [currentCountry, navigate]
  );

  return (
    <div className="outer-container" style={{ marginTop: "4rem" }}>
      <h1>Signature Journeys</h1>
      <div className="signature-journeys-container">
        <div className="sj-heading-container">
          <div className="dropdown" onClick={() => setIsOpen(!isOpen)}>
            <h3>{currentCountry}</h3>
            <div className="dropdown-content">
              <ul
                style={{
                  width: isOpen ? "10rem" : 0,
                  height: isOpen ? "4rem" : 0,
                  display: isOpen ? "flex" : "none",
                  flexDirection: "column",
                }}
              >
                {renderCountryList}
              </ul>
            </div>
          </div>
        </div>

        <div className="sj-card-container" style={{ transition: "all 29.3s" }}>
          {renderCards}
        </div>
        <button
          onClick={navToCountry}
          style={{ alignSelf: width < 600 && "center" }}
          className="button-greyish"
        >
          Start your journey
        </button>
      </div>
    </div>
  );
};
