import React, { useCallback, useState } from "react";
import wl_logo from "../static/images/WL_LOGO.svg";
import wl_logo_short from "../static/images/logos/wl_logo_short_coloured.svg";
import wl_logo_long from "../static/images/logos/WL_Logo-02.svg";
import "../sass/layout/_footer2.scss";
import "../sass/main.scss";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import checkIcon from "../static/images/icons/checkMark.svg";
import axios from "axios";

export const Footer = () => {
  const { width } = useWindowDimensions();
  const [email, setEmail] = useState("");
  const [renderTick, setRenderTick] = useState(false);

  const onClick = useCallback(async () => {
    if (email) {
      const response = await axios
        .post("https://wander-server.vercel.app/api/subscribe", { email })
        .catch((e) => console.log("There was an error while subscribing..", e));

      if (response.data.success) {
        setRenderTick(true);
        setTimeout(() => {
          setRenderTick(false);
        }, 10000);
      }
    }
  }, [email]);

  return (
    <div className="footer">
      <div className="footer-left">
        <div className="footer-left-up">
          <div className="footer-section">
            <div className="footer-section-label">Quick Links</div>
            <div className="footer-section-links">
              <a href="/">Home</a>
              <a href="/about">About Us</a>
              <a href="/contact">Contact Us</a>
              <a href="/book">Book Now</a>
              <a href="/faq">FAQ</a>
            </div>
          </div>

          <div
            className="footer-section"
            // style={{ width: width < 600 ? "50%" : "100%" }}
          >
            <div className="footer-section-label">Destinations</div>
            <div className="footer-section-links">
              <a href="/:India">India</a>
              <a href="/:Bhutan">Bhutan</a>
              <a href="/:SriLanka">Sri Lanka</a>
            </div>
          </div>

          <div className="footer-section">
            <div className="footer-section-label">Travel Experience</div>
            <div className="footer-section-links">
              <ul>
                <div>Luxury journeys and empowering adventures</div>
                <div>Exclusively for women</div>
              </ul>

              <div style={{ marginTop: width > 600 ? "1rem" : "3rem" }}>
                <div
                  style={{ alignSelf: "flex-end" }}
                  className="footer-section-label"
                >
                  Terms and Conditions apply
                </div>
                {/* <div className="footer-section-links">
                  <a href="mailto:info@wanderluxe.sg">info@wanderluxe.sg</a>
                </div>{" "} */}
              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-section-label">Follow Us</div>
            <div className="footer-section-links">
              <div>Sailing Seascapes PTE. LTD.</div>
              <div>TA03724</div>
            </div>

            <div className="footer-section-links">
              <a href="mailto:info@wanderluxe.sg">info@wanderluxe.sg</a>
            </div>
          </div>
        </div>
        <div className="footer-left-down">
          <object data={wl_logo} type="image/svg+xml">
            WanderLuxe
          </object>
        </div>
      </div>

      <div className="footer-right">
        <div className="footer-section">
          <div className="footer-right-form">
            <label>SUBSCRIBE TO OUR NEWSLETTER :</label>
            <div className="footer-right-form-content">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="email"
                name="email"
                placeholder="someone@gmail.com"
              />
              <button
                disabled={renderTick}
                onClick={onClick}
                className="footer-button"
              >
                {renderTick ? (
                  <img
                    src={checkIcon}
                    height={20}
                    width={25}
                    alt="Subscribe to newsletter done!!"
                  />
                ) : (
                  "Subscribe"
                )}
              </button>
            </div>
          </div>
        </div>

        <div
          className="footer-section logo"
          style={{ justifyContent: "space-between" }}
        >
          <object
            data={width > 600 ? wl_logo_short : wl_logo_long}
            type="image/svg+xml"
          >
            Wanderluxe
          </object>

          <div className="footer-copyright">
            <div>© Wanderluxe SG 2024</div>
            <div>Designed by Black Prism Designs</div>
          </div>
        </div>
      </div>
    </div>
  );
};
