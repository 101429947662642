import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/landing.js";
import { Navbar } from "./components/Navbar.js";
import { Footer } from "./pages/footer.js";
import { SheVentures } from "./pages/sheVentures.js";
import { SignatureJourneys } from "./components/SignatureJourneys.js";
import { OurEthos } from "./pages/OurEthos.js";
import { Experiences } from "./pages/experiences.js";
import { Banner } from "./components/banner.js";
import { ContactUs } from "./pages/contactUs.js";
import { AboutUs } from "./pages/AboutUs.js";
import { Destination } from "./pages/Destination.js";
import { Testimonials } from "./pages/Testimonials.js";
import useWindowDimensions from "./hooks/useWindowDimensions.js";
import { useEffect, useState } from "react";
import { Product } from "./pages/Product.js";
import { BookNow } from "./components/BookNow.js";
import { FAQ } from "./pages/FAQ.js";

function App() {
  const [landingBannerFontSize, setLandingBannerFontSize] = useState("7.5rem");
  const [bookNowBannerFontSize, setBookNowBannerFontSize] = useState("2.5rem");
  const [hideDestinationHeading, setHideDestinationHeading] = useState(false);
  const [increaseProductHeadingIndex, setIncreaseProductHeadingIndex] =
    useState(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 900 && width <= 1200) {
      setLandingBannerFontSize("6.8rem");
      setBookNowBannerFontSize("1.8rem");
      return;
    } else if (width > 600 && width <= 900) {
      setLandingBannerFontSize("4.8rem");
      setBookNowBannerFontSize("1.5rem");
      return;
    } else if (width <= 600) {
      setLandingBannerFontSize("3.4rem");
      setBookNowBannerFontSize("1.4rem");
      return;
    }

    setLandingBannerFontSize("7.5rem");
    setBookNowBannerFontSize("2.5rem");
    return;
  }, [width]);

  return (
    <BrowserRouter>
      <Navbar
        increaseProductHeadingIndex={increaseProductHeadingIndex}
        setIncreaseProductHeadingIndex={setIncreaseProductHeadingIndex}
        setHideDestinationHeading={setHideDestinationHeading}
        hideDestinationHeading={hideDestinationHeading}
      />

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Landing />
              <OurEthos />
              <SheVentures />
              <SignatureJourneys />
              <Experiences />
              <div style={{ margin: "5rem 0" }}></div>
              <Testimonials />
              <Banner
                addLetterSpacing={width > 600}
                heading={"Travel With Us"}
                fontSize={landingBannerFontSize}
                renderIcons={true}
                renderFormButton={true}
                backgroundColor={"#97789B"}
                width={"100%"}
                style={{ alignSelf: "center" }}
              />
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <AboutUs />
              <Banner
                addLetterSpacing={width > 600}
                heading={"Travel With Us"}
                fontSize={landingBannerFontSize}
                renderIcons={true}
                renderFormButton={true}
                backgroundColor={"#97789B"}
                width={"100%"}
              />
            </>
          }
        />
        <Route
          path="/:destination"
          element={
            <>
              <Destination hideDestinationHeading={hideDestinationHeading} />
              <Banner
                heading={"Travel With Us"}
                addLetterSpacing={width > 600}
                fontSize={landingBannerFontSize}
                renderIcons={true}
                renderFormButton={true}
                backgroundColor={"#97789B"}
              />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <ContactUs />
            </>
          }
        />
        <Route
          path="/:destination/:subDestination"
          element={
            <>
              <Product
                increaseProductHeadingIndex={increaseProductHeadingIndex}
              />
              <Banner
                heading={"Travel With Us"}
                addLetterSpacing={width > 600}
                fontSize={landingBannerFontSize}
                renderIcons={true}
                width={"100%"}
                renderFormButton={true}
                backgroundColor={"#97789B"}
              />
            </>
          }
        />

        <Route
          path="/book"
          element={
            <>
              <Banner
                width={width > 600 ? "70%" : "100%"}
                renderIcons={false}
                fontSize={bookNowBannerFontSize}
                textTransform={"initial"}
                heading={
                  "Your travel dreams are just a message or a call away. We look forward to helping you make your journey extraordinary."
                }
                backgroundColor={"#97b2b7"}
              />
              <BookNow />
              <Banner
                heading={"Travel With Us"}
                fontSize={landingBannerFontSize}
                addLetterSpacing={width > 600}
                renderIcons={true}
                renderFormButton={true}
                backgroundColor={"#97789B"}
                width={"100%"}
              />
            </>
          }
        />
        <Route path="/faq" element={<FAQ />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
