import React, { useCallback, useState } from "react";
import "../sass/layout/_signatureJourneys.scss";
import "../sass/main.scss";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import LazyImage from "./LazyImage.js";

import closeIconSmall from "../static/images/icons/closeIconSmall.png";

export const SecondCard = ({
  dest,
  index,
  setHighlightedIndex,
  img,
  navTo,
  navToFunction,
  shouldRenderEnquireButton,
}) => {
  const navigate = useNavigate();

  const { width } = useWindowDimensions();

  const toggleHighlight = (index) => {
    setHighlightedIndex(index);
  };

  const resetHighlight = () => {
    setHighlightedIndex(null);
  };

  const [openModal, setOpenModal] = useState(false);

  const onOpen = useCallback(() => setOpenModal(true), []);
  const onClose = useCallback(() => setOpenModal(false), []);

  const onClick = useCallback(
    () => (navTo ? navigate(navTo) : null),
    [navTo, navigate]
  );

  return (
    <div
      key={index}
      className="jr-card"
      onMouseEnter={() => toggleHighlight(index)}
      onMouseLeave={resetHighlight}
    >
      <LazyImage
        component={
          <div
            className="jr-card-upper"
            style={{
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
            }}
          >
            <button className="button-primary" onClick={onOpen}>
              Quick View
            </button>
            <Modal
              closeOnEsc
              styles={{
                modalContainer: {
                  position: "relative",
                },
                modal: {
                  backgroundColor: "#97b2b7",
                  padding: "2rem",
                },
                closeButton: {
                  backgroundColor: "#97b2b7",
                  position: "absolute",
                  top: width < 600 ? 0 : 10,
                  right: 0,
                },
              }}
              open={openModal}
              onClose={onClose}
              closeIcon={
                <img
                  style={{}}
                  src={closeIconSmall}
                  alt="Close"
                  height={35}
                  width={35}
                />
              }
            >
              <div className="modal">
                <div
                  style={{
                    backgroundImage: `url(${img})`,
                    backgroundSize: "cover",
                  }}
                  className="modal-left"
                ></div>
                <div className="modal-right">
                  <h3 className="jr-card-lower-heading">
                    {dest.description}
                    <br />
                    {dest.sub_description}
                  </h3>
                  <div className="modal-right-description">
                    {Array.isArray(dest.attractions)
                      ? dest.attractions.map((attr) => {
                          return (
                            <ul style={{ listStyle: "none" }}>
                              <li>
                                <h1>{attr.dest}</h1>
                              </li>
                              <li style={{ marginTop: "-2rem" }}>
                                {attr.descrip}
                              </li>
                            </ul>
                          );
                        })
                      : dest.attractions}
                  </div>
                  {/* {width > 600 && ( */}
                  {shouldRenderEnquireButton && (
                    <button
                      style={{ marginTop: "2rem" }}
                      className="button-pinkish"
                      onClick={navToFunction}
                    >
                      Enquire Now
                    </button>
                  )}
                  {/* )} */}
                </div>
              </div>
            </Modal>
          </div>
        }
      />
      <div onClick={onClick} className="jr-card-lower">
        <h3 className="jr-card-lower-heading">{dest.destination}</h3>
        <p className="jr-card-lower-heading-sub">{dest.description}</p>
        <p className="jr-card-lower-heading-description">
          {dest.sub_description}
        </p>
        <div
          onClick={onClick}
          className="arrow-container"
          style={{ marginTop: "2rem" }}
        >
          <div
            key={index}
            className="diamond"
            style={{
              backgroundColor: "transparent",
              border: "0.7px solid white",
            }}
          >
            <div className="arrow" style={{ backgroundColor: "white" }}>
              <div
                className="arrow-before"
                style={{
                  borderTop: "0.7px solid white",
                  borderRight: "0.7px solid white",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
