import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import journeys from "../static/data/journeys.js";
import amritsar from "../static/images/amritsar.jpg";
import kerela from "../static/images/kerela.jpg";
import kashmir from "../static/images/kashmir.jpg";
import ladakh from "../static/images/ladakh.jpg";
import paro from "../static/images/product/paro/paro_intro.jpg";
import bentota from "../static/images/product/bentota/bentota_intro.jpg";
import thimphu from "../static/images/product/thimphu/thimphu_intro.jpg";
import colombo from "../static/images/product/colombo/colombo_intro.jpg";
import kandy from "../static/images/product/kandy/kandy_intro.jpg";
import punakha from "../static/images/product/punakha/punakha_intro.jpg";
import triangle from "../static/images/product/triangle/triangle_intro.jpg";

import "../sass/layout/_destination.scss";
import India from "../static/videos/desktop/india_dest.mp4";
import SriLanka from "../static/videos/desktop/sri_lanka_dest.mp4";
import Bhutan from "../static/videos/desktop/bhutan_dest.mp4";
import IndiaMobile from "../static/videos/mobile/india_dest_phone.mp4";
import SriLankaMobile from "../static/videos/mobile/sri_lanka_dest_phone.mp4";
import BhutanMobile from "../static/videos/mobile/bhutan_dest_phone.mp4";
import "../sass/layout/_signatureJourneys.scss";
import { Banner } from "../components/banner.js";
import { SecondCard } from "../components/journeyCard.js";
import "../sass/layout/_experiences.scss";
import "../sass/base/_typogrophy.scss";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import LazyImage from "../components/LazyImage.js";

const VIDEOS = {
  India,
  SriLanka,
  Bhutan,
};

const VIDEOS_MOBILE = {
  IndiaMobile,
  SriLankaMobile,
  BhutanMobile,
};
const imgs = {
  kerela,
  kashmir,
  amritsar,
  ladakh,
  paro,
  thimphu,
  punakha,
  colombo,
  kandy,
  bentota,
  triangle,
};

export const Destination = ({ hideDestinationHeading }) => {
  const [details, setDetails] = useState(null);
  const { destination } = useParams();
  const { width } = useWindowDimensions();
  const [bannerFontSize, setBannerFontSize] = useState("4.2rem");
  const navigate = useNavigate();

  useEffect(() => {
    if (width > 900 && width <= 1200) {
      setBannerFontSize("4rem");
      return;
    } else if (width > 600 && width <= 900) {
      setBannerFontSize("3.5rem");
      return;
    } else if (width <= 600) {
      setBannerFontSize("2rem");
      return;
    }

    // return setBannerFontSize("2rem");
  }, [width]);

  const [highlightedIndex, setHighlightedIndex] = React.useState(null);

  useEffect(() => {
    setDetails(journeys.find((dest) => destination.slice(1) === dest.country));
    window.scrollTo(0, 0);
  }, [destination]);

  const destinationDetails = React.useMemo(() => {
    if (!details) return null;
    return (
      <div>
        <div className="destination-container-left-upper">
          <h1
            style={{
              display: hideDestinationHeading ? "none" : "initial",
              top:
                details.destinationIntro === "Bhutan Enchantment" &&
                width < 600 &&
                0,
            }}
          >
            {details.destinationIntro}
          </h1>
          <LazyImage
            component={
              <video
                style={{ width: "100%", marginTop: width < 600 && "15vh" }}
                src={
                  width > 600
                    ? VIDEOS[destination.slice(1)]
                    : VIDEOS_MOBILE[destination.slice(1) + "Mobile"]
                }
                autoPlay
                muted
                loop
              />
            }
          />
        </div>
      </div>
    );
  }, [destination, details, hideDestinationHeading, width]);

  const renderBanner = React.useMemo(() => {
    if (
      !details ||
      (destination && destination.slice(1).toLowerCase() === "india")
    ) {
      return;
    }
    return (
      <Banner
        addLetterSpacing={width > 600}
        id={"scrollToBanner"}
        renderIcons={false}
        fontSize={bannerFontSize}
        textTransform={"Uppercase"}
        heading={"Coming soon"}
        backgroundColor={"#b3c8d3"}
      />
    );
  }, [details, destination, width, bannerFontSize]);

  const renderCards = React.useMemo(() => {
    if (!details) return null;
    return details.tours.map((dest, index) => {
      const shouldHighlight = highlightedIndex === index;
      console.log("Dest at Dest ", dest.destination);
      return (
        <SecondCard
          navToFunction={() => {
            navigate("/book", {
              state: {
                country: destination.slice(1),
                destination: dest.destination,
              },
            });
          }}
          shouldRenderEnquireButton={dest.attractions !== "Coming Soon"}
          navTo={`/:${destination.slice(1)}/:${dest.destination}`}
          key={index}
          dest={dest}
          img={imgs[dest.img_filename]}
          setHighlightedIndex={setHighlightedIndex}
          shouldHighlight={shouldHighlight}
          index={index}
        />
      );
    });
  }, [details, highlightedIndex, destination, navigate]);

  return (
    <>
      <div className="destination-container">
        {destinationDetails}
        <div className="destination-container-right"></div>
      </div>

      {renderBanner}
      <div
        style={{
          justifyContent:
            details && details.tours.length === 3
              ? "space-around"
              : "space-between",
        }}
        className="destination-card-container"
      >
        {renderCards}
      </div>
    </>
  );
};
