import client_safety from "../images/ourEthos/WL__client safety.jpg";
import curated_experiences from "../images/ourEthos/WL__curated experience.jpg";
import personalised_service from "../images/ourEthos/WL__personalised service.jpg";
import woman_empowerment from "../images/ourEthos/WL__women empowerment.jpg";

const data = [
  {
    icon: "curated_experiences",
    heading: "Curated Experiences",
    description:
      "Each tour is meticulously crafted to provide unique and unforgettable experiences that resonate with women travelers.",
    image: curated_experiences,
  },
  {
    icon: "client_safety",
    heading: "Client Safety",
    description:
      "Prioritizing the safety and well-being of our clients, ensuring secure and comfortable journeys.",
    image: client_safety,
  },
  {
    icon: "woman_empowerment",
    heading: "Woman Empowerment",
    description:
      "Led by passionate women explorers, empowering women through travel.",
    image: woman_empowerment,
  },
  {
    icon: "personalised_service",
    heading: "Personalized Service",
    description:
      "Our team is dedicated to understanding and catering to your personal preferences and interests.",
    image: personalised_service,
  },
];

export default data;
