import amritsar_main from "../images/product/amritsar/amritsar_main.jpg";
import amritsar_intro from "../images/product/amritsar/amritsar_intro.jpg";
import amritsar_bespoke from "../images/product/amritsar/amritsar_bespoke.webp";
import amritsar_curated from "../images/product/amritsar/amritsar_curated.jpg";
import amritsar_special from "../images/product/amritsar/amritsar_event.webp";
import amritsar_outro from "../images/product/amritsar/amritsar_outro.jpg";

import kerela_main from "../images/product/kerela/kerela_main.jpg";
import kerela_intro from "../images/product/kerela/kerela_intro.jpg";
import kerela_bespoke from "../images/product/kerela/kerela_bespoke.jpg";
import kerela_curated from "../images/product/kerela/kerela_curated.jpg";
import kerela_special from "../images/product/kerela/kerela_event.jpg";
import kerela_outro from "../images/product/kerela/kerela_outro.jpg";

import ladakh_main from "../images/product/ladhak/ladakh_main.jpg";
import ladakh_intro from "../images/product/ladhak/ladakh_intro.jpg";
import ladakh_bespoke from "../images/product/ladhak/ladakh_bespoke.jpg";
import ladakh_curated from "../images/product/ladhak/ladakh_curated.jpg";
import ladakh_special from "../images/product/ladhak/ladakh_event.jpg";
import ladakh_outro from "../images/product/ladhak/ladakh_outro.jpg";

import kashmir_main from "../images/product/kashmir/kashmir_main.jpg";
import kashmir_intro from "../images/product/kashmir/kashmir_intro.jpg";
import kashmir_bespoke from "../images/product/kashmir/kashmir_bespoke.webp";
import kashmir_curated from "../images/product/kashmir/kashmir_curated.webp";
import kashmir_special from "../images/product/kashmir/kashmir_intro.webp";
import kashmir_outro from "../images/product/kashmir/kashmir_main.webp";

import triangle_main from "../images/product/triangle/triangle_main.jpg";
import triangle_intro from "../images/product/triangle/triangle_intro.jpg";
import triangle_bespoke from "../images/product/triangle/triangle_bespoke.jpg";
import triangle_curated from "../images/product/triangle/triangle_curated.jpg";
import triangle_special from "../images/product/triangle/triangle_special.jpg";
import triangle_outro from "../images/product/triangle/triangle_outro.jpg";

import paro_main from "../images/product/paro/paro_main.jpg";
import paro_intro from "../images/product/paro/paro_intro.jpg";
import paro_bespoke from "../images/product/paro/paro_bespoke.jpg";
import paro_curated from "../images/product/paro/paro_curated.jpg";
import paro_outro from "../images/product/paro/paro_intro.jpg";

import punakha_main from "../images/product/punakha/punakha_main.jpg";
import punakha_intro from "../images/product/punakha/punakha_intro.jpg";
import punakha_bespoke from "../images/product/punakha/punakha_bespoke.jpg";
import punakha_curated from "../images/product/punakha/punakha_curated.jpg";
import punakha_outro from "../images/product/punakha/punakha_outro.jpg";

import thimphu_main from "../images/product/thimphu/thimphu_intro.jpg";
import thimphu_intro from "../images/product/thimphu/thimphu_intro.jpg";
import thimphu_bespoke from "../images/product/thimphu/thimphu_bespoke.jpg";
import thimphu_curated from "../images/product/thimphu/thimphu_curated.jpg";
import thimphu_outro from "../images/product/thimphu/thimphu_main.jpg";

import bentota_main from "../images/product/bentota/bentota_bespoke.jpg";
import bentota_intro from "../images/product/bentota/bentota_intro.jpg";
import bentota_bespoke from "../images/product/bentota/bentota_bespoke.jpg";
import bentota_curated from "../images/product/bentota/bentota_curated.jpg";
import bentota_outro from "../images/product/bentota/bentota_intro.jpg";

import colombo_main from "../images/product/colombo/colombo_bespoke.jpg";
import colombo_intro from "../images/product/colombo/colombo_intro.jpg";
import colombo_bespoke from "../images/product/colombo/colombo_bespoke.jpg";
import colombo_curated from "../images/product/colombo/colombo_curated.jpg";
import colombo_outro from "../images/product/colombo/colombo_intro.jpg";

import kandy_main from "../images/product/kandy/kandy_bespoke.jpg";
import kandy_intro from "../images/product/kandy/kandy_intro.jpg";
import kandy_bespoke from "../images/product/kandy/kandy_bespoke.jpg";
import kandy_curated from "../images/product/kandy/kandy_curated.jpg";
import kandy_outro from "../images/product/kandy/kandy_intro.jpg";

import amritsar_it from "../Iteneraries/india/amritsar_it.pdf";
import kashmir_it from "../Iteneraries/india/kashmir_it.pdf";
import kerala_it from "../Iteneraries/india/kerala_it.pdf";
import ladakh_it from "../Iteneraries/india/leh_ladakh_srinagar.pdf";
import triangle_it from "../Iteneraries/india/goldenTriangle_it.pdf";

const IMAGES = {
  amritsar: {
    main: amritsar_main,
    intro: amritsar_intro,
    bespoke: amritsar_bespoke,
    curated: amritsar_curated,
    special: amritsar_special,
    outro: amritsar_outro,
  },
  ladakh: {
    main: ladakh_main,
    intro: ladakh_intro,
    bespoke: ladakh_bespoke,
    curated: ladakh_curated,
    special: ladakh_special,
    outro: ladakh_outro,
  },
  kashmir: {
    main: kashmir_main,
    intro: kashmir_intro,
    bespoke: kashmir_bespoke,
    curated: kashmir_curated,
    special: kashmir_special,
    outro: kashmir_outro,
  },
  kerela: {
    main: kerela_main,
    intro: kerela_intro,
    bespoke: kerela_bespoke,
    curated: kerela_curated,
    special: kerela_special,
    outro: kerela_outro,
  },
  triangle: {
    main: triangle_main,
    intro: triangle_intro,
    bespoke: triangle_bespoke,
    curated: triangle_curated,
    special: triangle_special,
    outro: triangle_outro,
  },
  punakha: {
    main: punakha_main,
    intro: punakha_intro,
    bespoke: punakha_bespoke,
    curated: punakha_curated,
    outro: punakha_outro,
  },
  thimphu: {
    main: thimphu_main,
    intro: thimphu_intro,
    bespoke: thimphu_bespoke,
    curated: thimphu_curated,
    outro: thimphu_outro,
  },
  paro: {
    main: paro_main,
    intro: paro_intro,
    bespoke: paro_bespoke,
    curated: paro_curated,
    outro: paro_outro,
  },
  bentota: {
    main: bentota_main,
    intro: bentota_intro,
    bespoke: bentota_bespoke,
    curated: bentota_curated,
    outro: bentota_outro,
  },
  colombo: {
    main: colombo_main,
    intro: colombo_intro,
    bespoke: colombo_bespoke,
    curated: colombo_curated,
    outro: colombo_outro,
  },
  kandy: {
    main: kandy_main,
    intro: kandy_intro,
    bespoke: kandy_bespoke,
    curated: kandy_curated,
    outro: kandy_outro,
  },
};

const data = [
  {
    country: "India",
    destinationIntro: "India Immersive",
    destinationIntroSub: "A tapestry of colors, cultures and contrast",
    details: [
      "Immerse yourself in the height of luxury amid the splendor of India's most captivating destinations. Redefine the essence of luxury travel, specially crafted for female travelers, in the heart of Incredible India.",
      "Stay in exquisite accommodations, unwind at serene wellness retreats, and delight in culinary masterpieces that blend tradition and innovation. Explore the bustling markets with personalized shopping tours, discover hidden cultural gems, and enjoy bespoke adventures tailored to your interests. Experience India in a way that harmonizes elegance, authenticity, and unforgettable moments.",
    ],
    cityList: [
      "Amritsar",
      "Jaipur",
      "Delhi",
      "Kerala",
      "Kashmir",
      "Ladakh",
      "",
    ],
    tours: [
      {
        carousel: {
          subDest: "Amritsar",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1>Amritsar\'s Golden Hub:</h1></div></div>',
        },
        background_color: "#97789b",
        destination: "Amritsar",
        description: "Amritsar's Golden Hub:",
        sub_description: "The Soul of Punjab",
        capital: "Chandigarh",
        bestTime_bespoke: "Year Round",
        bestTime_curated: "October-March",
        bestTime_special: "Seasonal",
        currency: "Indian Rupee (INR)",
        languages: "Punjabi",

        tz: "UTC+5:30",
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        pax_special: "4-6 pax upwards",
        bespoke: [
          "Discover the essence of Punjab with our curated bespoke private tours in Amritsar.",
          "Exclusively customised to your preferences, our tailor-made experiences offer an immersive spiritual and cultural tour to the heart of the city.",
          "Capture the unique essence and vibrant tapestry of Amritsar; from the awe-inspiring Golden Temple to the hidden cultural gems.",
        ],
        curated: [
          "Connect with fellow women travellers or gather a group of likeminded female friends for a journey through our “Our Soul of Punjab”, Amritsar group tour.",
          "Thoughtfully curated; delve into the spiritual and cultural treasures of what Amritsar has to offer and discover the vibrant tapestry of it’s heritage.",
          "Create unforgettable memories and ignite the soul of Punjab together.",
        ],
        special: [
          "Our exclusive Special Event Holiday package is specially designed for those seeking to experience the unique events and celebrations of Amritsar.",
          "Carefully curated are some must-visit events such as the Holy procession of Baisakhi, the Basant Panchami fanfare welcoming the spring season, where locals are vibrantly draped in a sea of yellow, or immerse yourself in the festival of lights during Diwali and Bando Chor Diwas celebrated in great fanfare, attracting visitors from far and wide.",
          "Experience Amritsar like never before.",
        ],
        link: "https://www.something.com",
        img_filename: "amritsar",
        itPdf: amritsar_it,
        images: IMAGES["amritsar"],
        outro: "A Glimpse Into the Region’s Timeless Spirit",
        attractions:
          "Golden Temple, Community Kitchens, Jallianwala Wala Bagh Martyrs Memorial, and an afternoon excursion to the Wagah border to witness the flag ceremony!",
      },
      {
        carousel: {
          subDest: "Ladakh",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1>A Himalayan Majesty</h1></div></div>',
        },
        background_color: "#d1bdaf",
        destination: "Ladakh",
        images: IMAGES["ladakh"],
        itPdf: ladakh_it,
        capital: "Leh",
        description: "Ladakh Panorama:",
        sub_description: "A Himalayan Majesty",
        link: "https://www.something.com",
        img_filename: "ladakh",
        bestTime_special: "April-September",
        bestTime_bespoke: "Year Round",
        bestTime_curated: "October-March",
        bespoke: [
          "Explore the best-preserved Buddhist societies in our bespoke private Ladakh experience. Enjoy the charming sites of villages built from sun-bakedmud bricks, breathtaking panoramic views of the Himalayans and soak in the majestic beauty of the world that is entirely unspoilt. Every corner reveals a story waiting to be told. Let us craft the perfect itinerary specially for your much deserved exploration where every moment becomes a cherished memory.",
        ],
        curated: [
          "Discover the untouched beauty and breathtaking region of Ladakh.Soak in the majestic mountains, ancient monasteries, and thrilling adventures amidst the rugged terrain of the region’s beauty. Our tailor-made group tours offer unique travel experiences like never before. Every moment is a reflection of serenity and tranquillity waiting to be discovered.Gather your fellow female friends and explore the wonders of nature in Himalayan Majestic tour.",
        ],
        special: [
          "Beyond the rugged mountains and spellbinding Himalayan views; Ladakhis rich cultural heritage can also be witnessed in their festivals and simple lifestyle that make it a very unique experience for the visiting tourist. Celebrate the festivals with the locals and enjoy a variety of cultural ceremonies from folklore songs to street processions. Festivals are a perfect time to indulge in traditional delicacies and important festivals from Hemisand Ladakh festivals. A perfect and unique journey that unveils the diverse offering of Ladakh.",
        ],
        outro: "Breathtaking Landscapes Meet Boundless Adventure",
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        pax_special: "1-2 pax upwards",
        currency: "Indian Rupee (INR)",
        languages: "Ladakhi, Tibetan and Urdu",
        tz: "UTC+5:30",
        attractions:
          "Leh - Visit Shanti Stupa / Leh Palace, Thiksey, Hemis monastery / Leh Market etc. Nubra Valley - Visit Nubra velley via Khardungla. Excursion to Hunder/ Diskit/ and Shayok en-route to Pangong Lake. Excursion to T-somoriri Lake.",
      },
      {
        subDest: "Kashmir",
        carousel: {
          subDest: "Kashmir",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1>Kashmir\'s Blissful Symphony</h1></div></div>',
        },
        background_color: "#b3c8d3",
        itPdf: kashmir_it,
        destination: "Kashmir",
        images: IMAGES["kashmir"],
        description: "Kashmir's Blissful Symphony:",
        sub_description: "Valleys of Enchantment",
        link: "https://www.something.com",
        img_path: "../images/kashmir.jpg",
        img_filename: "kashmir",
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        pax_special: "4-6 pax upwards",
        bestTime_bespoke: "Year Round",
        bestTime_curated: "October-March",
        bestTime_special: "October-March",
        capital: "Srinagar",
        bestTime: "October-March",
        currency: "Indian Rupee (INR)",
        languages: "Urdu, Kashmiri, Dogri and English",
        outro: "Captivating Beauty in The Heart of the Himalayas",
        bespoke: [
          "Discover the allure of Kashmir through bespoke journeys designed for the discerning traveler.",
          "Unfold each moment and explore the vibrant culture of Srinagar’s old city, the serene beauty of Dal Lake, unwind amidst the tranquilityof NigeenLake, and experience the adrenaline rush of skiing in Gulmarg.",
          "Tailored exclusively for women travelers, our curated experiences offer a blend of luxury, culture, and adventure in the enchanting valley of Kashmir.",
        ],
        curated: [
          "Our exclusive group tour  tailored for women travelers promises an experience like no other.  Join fellow adventurers for unforgettable lake retreats and thrilling ski adventures.",
          "Explore, connect, and create memories together in the majestic beauty and charms of Kashmir.",
        ],
        special: [
          "Elevate your wanderlust on a remarkable journey to Kashmir, tailored for women travelers.",
        ],
        tz: "UTC+5:30",
        attractions:
          "Srinagar - the famous Gardens, such as Mughal garden, Shalimar Garden, Shalimar Bagh, Chashme Sahi garden & Shikara ride on the famous Dal Lake. Pahalgam - visit Baisaran Road, Betab valley, Overa Aru valley etc. You can also enjoy several adventure activity such as Pony ride, Horse ride or Paragliding etc. Gulmarg- enjoy Gandola ride (Cable car ride) 1st and 2nd phase based on availability. Ride from Gulmarg to Khilanmarg to have a real view in natural beauty of the summer and full snow view in winter.",
      },
      {
        carousel: {
          subDest: "Kerala",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1><Kashmir\'s Blissful Symphony</h1></div></div>',
        },
        background_color: "#d493a1",
        itPdf: kerala_it,
        destination: "Kerala",
        description: "Kerala's Crown Jewels:",
        sub_description: "Culture, Cuisine & Coast",
        img_path: "../images/kerela.jpg",
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        pax_special: "4-6 pax upwards",
        bestTime_bespoke: "Year Round",
        bestTime_curated: "October-March",
        bestTime_special: "Seasonal",
        bespoke: [
          "Our bespoke private experience to Kerala is specially tailored to your preference, ensuring an exclusive and personalized adventure for women travellers.",
          "From tranquil backwaters to verdant landscapes and pristine shores, soak in the essence of Kerala. Indulge in the rich flavors of local cuisine, rejuvenate with ancient wellness traditions, and delve into cultural exploration. Every aspect of your journey is uniquely designed to cater to your needs.",
          "Elevate your experience with vibrant cultural festivals or relax in a serene Ayurvedic retreat. Create lasting memories as you embark on your bespoke journey of discovery.",
        ],
        curated: [
          "Experience an unforgettable journey with our exclusive curated group tours designed specifically for women travellers in Kerala!",
          "Explore the serene backwaters, lush landscapes, and pristine beaches while indulging in exquisite regional cuisine, rejuvenating wellness practices, and uncovering cultural treasures.",
          "Join us for a luxurious group adventure that promises unparalleled experiences and unrivaled comfort at every turn. Your journey of indulgence awaits.",
        ],
        special: [
          "Enjoy the beauty and culture of Kerala with our exclusive Special Event Holiday package for women travelers.",
          "Our packages are carefully curated for women seeking to experience the unique celebrations and events of Kerala such as the enchanting Onam festival, Chinakkathoor Pooram (Elephant procession),the colorful Navaratri and many more.",
          "Your extraordinary journey to 'God's Own Country' begins here, promising unforgettable moments and incomparable experiences.",
        ],
        link: "https://www.something.com",
        img_filename: "kerela",
        images: IMAGES["kerela"],
        bestTime: "Year round",
        currency: "Indian Rupee (INR)",
        languages: "Malayalam",
        capital: "Thiruvananthapuram",
        tz: "UTC+5:30",
        outro: "A Harmony of Flavours and Lush Landscapes",
        attractions:
          "Kerala mural paintings are the frescos depicting Hindu mythology in Kerala. Based on ancient temples and palaces in Kerala, India, display an abounding tradition of mural paintings mostly dating back between the 9th to 12th centuries CE when this form of art enjoyed royal patronage.",
      },
      {
        carousel: {
          subDest: "Delhi, Agra & Jaipur",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1>The Golden Triangle</h1></div></div>',
        },
        background_color: "#d493a1",
        destination: "Delhi, Agra & Jaipur",
        description: "The Golden Triangle",
        capital: "New Delhi",
        itPdf: triangle_it,
        sub_description: "",
        bespoke: [
          "Be captivated by the breathtaking beauty of the Taj Mahal and explore the rich heritage of the Agra Fort.",
          "Explore India's Pink City; Jaipur through the majestic halls of Amber Fort and explore the ornate City Palace.",
          "Soak in this bespoke private experience promising a unique blend of history, culture, and adventure, ensuring an unforgettable journey through India's iconic destinations.",
        ],
        curated: [
          "Uncover the rich history of the Red Fort and serene surroundings of the Lotus Temple. Be captivated by the eternal beauty of the Taj Mahal in Agra and delve into history within Agra Fort's walls.",
          "Immerse in the vibrant bazaars of the Pink City; Jaipur, discovering treasures and soaking in the colorful culture of Rajasthan.",
          "Explore this unforgettable journey through India's golden triangle crafted just for your fellow women travellers.",
        ],
        special: [
          "In Delhi, soak in the bustling DastkarNature Bazaar and experience the creativity of the Delhi flower show. Witness the colorfulkites that fill the skyline during the Jaipur Kite Festival and immerse yourself in the massive TeejFestival celebrated by the womenfolk in Jaipur.",
          "the massive TeejFestival celebrated by the womenfolk in Jaipur. Finally, experience Agra's most awaited festival, Taj Mahotsav, celebrated with awe-inspiring processions and great zeal!",
          "Every celebration has a touch of richness and zest to it. Experience a unique holiday with our curated special events tour.",
        ],
        img_filename: "triangle",
        bestTime: "April-September",
        currency: "Indian Rupee (INR)",
        images: IMAGES["triangle"],
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        pax_special: "4-6 pax upwards",
        bestTime_bespoke: "Year Round",
        bestTime_curated: "October-March",
        bestTime_special: "Seasonal",
        languages: "Ladakhi, Tibetan and Urdu",
        tz: "UTC+5:30",
        outro: "Journey through India’s Rich Heritage and Wonders ",
        attractions: [
          {
            dest: "Delhi",
            descrip:
              "New Delhi - Qutab Minar, Lotus temple or Bahai House bo worship, India Gate, President House, Humayun's tomb, Gurudwara Bangla Sahib (Sikh temple), Delhi Haat, city centre Connaught Place markets etc. Old Delhi - Jama masjid, explore Chandni Chowk market by cycle rickshaw ride, Red Fort, Raj Ghat (Mahatma Gandhi cremation place) etc. ! these places can be coverred if someone stays two nights in Delhi ! other places such as Heritage walk tour, Art walk at Lodhi colony, Museum visit, Akshyardham temple tour etc. can be added with extra night stay in Delhi.",
          },
          {
            dest: "Agra",
            descrip:
              "Taj Mahal visit- with Premium entry tickets for foreign passprots, Agra Fort, Itmad-Ud-Daulah or the Baby Taj and visit Marble Art gallerry!  Ideally guest stay only one night in Agar and these are the highlighted points can be coverred, if someone would stay additioanl night, we can suggest Firozabad excursion (50 km/ 1.5 hours drive).",
          },
          {
            dest: "Jaipur",
            descrip:
              "Optional visit to Amrapali musuem. Evening colourful markets and Bazar excursion by cycle rickshaw ride, dinner at Rambagh Palace or in a Heritage Hotel such as Narain Niwas Palace with traditional dance show OR visit Chokhi Dhani ethnic village resort and enjoy traditional dinner with cultural program.  Morning visit Amber Fort with Elephant ride, followed by City Palace, Albert musuem, then visit  Observatory, Jal Mahal,. Palace of winds etc. Evenign Optional visit Sanganer Block printinig workshop + Gem Palace and Johari bazzar.",
          },
        ],
      },
    ],
  },
  {
    country: "Bhutan",
    destinationIntro: "Bhutan Enchantment",
    destinationIntroSub:
      "A collection of curated journeys that invite you to experience the enchanting beauty and rich culture of the Land of the Thunder Dragon. Explore the serene landscapes and vibrant towns of Bhutan with our handpicked tours and experiences.",
    details: [
      "Embrace the tranquil charm and luxury of Bhutan's unspoiled landscapes. Wanderluxe invites female travelers to experience the Land of the Thunder Dragon in unmatched style. Stay in exclusive mountain lodges offering breathtaking views, rejuvenate with holistic wellness treatments, and indulge in culinary delights that celebrate Bhutanese traditions. Delve into the culture with guided visits to ancient monasteries, vibrant markets, and secluded villages. Tailored adventures await, from serene hikes through pristine valleys to meditative retreats. Discover Bhutan's harmony of natural beauty, cultural richness, and luxurious comfort.",
    ],
    tours: [
      {
        carousel: {
          subDest: "Punakha",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1><Kashmir\'s Blissful Symphony</h1></div></div>',
        },
        background_color: "#d493a1",
        destination: "Punakha",
        images: IMAGES["punakha"],
        description: "A Journey to the Heart of Bhutan",
        sub_description: "",
        bespoke: [
          "Explore Punakha, the heart of Bhutan, at your own pace with a Bespoke Private Tour. Immerse yourself in the serene beauty and cultural richness of this enchanting destination, creating a personalized journey that truly touches the heart of Bhutan.",
        ],
        curated: [
          "Uncover the beauty and culture of Punakha with like-minded travelers on our Curated Group Tour. Immerse in the serene landscapes and cultural treasures of this heartwarming Bhutanese destination, creating shared memories that resonate with the heart of Bhutan.",
        ],
        special: [],
        img_filename: "punakha",
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        bestTime_bespoke: "Year round",
        bestTime_curated: "October-March",
        currency: "Bhutanese ngultrum (BTN), Indian Rupee (INR)",
        currencyNum: 2,
        languages: "Dzongkha",
        tz: "UTC+06:00",
        outro: "A Passage to Serenity and Ancient Traditions",
        attractions: "Coming Soon",
      },
      {
        carousel: {
          subDest: "Thimphu with Tiger's Nest",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1>The Capital and Beyond</h1></div></div>',
        },
        background_color: "#97789b",
        destination: "Thimphu",
        description: "Thimphu with Tiger's Nest:",
        sub_description: "The Capital and Beyond",
        images: IMAGES["thimphu"],

        link: "https://www.something.com",
        bespoke: [
          "Experience the iconic Tiger's Nest and Bhutan's capital, Thimphu, at your own pace with a Bespoke Private Tour. Immerse in the rich culture of Thimphu and undertake the awe-inspiring trek to the legendary Tiger's Nest monastery.",
        ],
        curated: [
          "Discover Thimphu and the legendary Tiger's Nest with like-minded travelers on our Curated Group Tour. Immerse in the city's cultural richness and embark on an adventure as you hike to the iconic Tiger's Nest monastery.",
        ],
        special: [],
        img_filename: "thimphu",
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        bestTime_bespoke: "Year round",
        bestTime_curated: "October-March",
        currency: "Bhutanese ngultrum (BTN), Indian Rupee (INR)",
        currencyNum: 2,
        languages: "Dzongkha",
        tz: "UTC+06:00",
        outro: "Embrace the Splendor of Bhutan’s Scared Monastry",
        attractions: "Coming Soon",
      },
      {
        carousel: {
          subDest: "Paro",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1>Gateway to Bhutan</h1></div></div>',
        },
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        bestTime_bespoke: "Year round",
        bestTime_curated: "October-March",
        background_color: "#d1bdaf",
        destination: "Paro",
        description: "A Gateway to Bhutan’s Enchanting Haven:",
        images: IMAGES["paro"],
        sub_description: "Gateway to Bhutan",
        link: "https://www.something.com",
        bespoke: [
          "Experience the allure of Bhutan's Paro at your own pace with a Bespoke Private Tour. Immerse yourself in the enchanting town of Paro, where history and culture converge seamlessly. Delve into the town's unique charm and heritage as you explore its captivating treasures.",
        ],
        curated: [
          "Uncover the magic of Bhutan's Paro with our Curated Group Tour. Explore the enchanting town of Paro in the company of fellow travelers. Immerse in the harmonious blend of history and culture, discovering the unique charm and heritage that define this picturesque Bhutanese destination.",
        ],
        special: [],
        currency: "Bhutanese ngultrum (BTN), Indian Rupee (INR)",
        currencyNum: 2,
        languages: "Dzongkha",
        tz: "UTC+06:00",
        img_filename: "paro",
        outro: "A Sanctuary of Tranquil Beauty and Cultural Riches",
        attractions: "Coming Soon",
      },
    ],
  },
  {
    carousel: {
      subDest: "Ladakh",
      content:
        '<div class="experiences"><div class="experiences-heading"><h1>Kashmir\'s Blissful Symphony</h1></div></div>',
    },
    country: "SriLanka",
    destinationIntro: "Sri Lanka Escapes",
    destinationIntroSub:
      "A collection of curated journeys that unveil the captivating beauty and cultural richness of this tropical paradise. Explore the diverse landscapes, vibrant cities, and pristine shores of Sri Lanka with our handpicked tours and experiences.",
    details: [
      "Unveil the wonders of Sri Lanka with a touch of luxury and elegance. Wanderluxe curates an exclusive experience for female travelers, showcasing the island's breathtaking beauty and vibrant culture.",
      "From luxurious hotels, world-class spa treatments, to savouring exquisite cuisine blending local flavors with international finesse. Discover the rich history of ancient temples, explore lush tea plantations, and stroll through bustling markets. Engage in bespoke adventures, from wildlife safaris in national parks to serene boat rides on tranquil lagoons. Experience Sri Lanka's perfect blend of natural splendor, cultural depth, and refined luxury.",
    ],
    tours: [
      {
        background_color: "#97789b",
        destination: "Kandy and Nuwara Eliya",
        description: "Hill Country Escape:",
        sub_description: "Kandy and Nuwara Eliya",
        link: "https://www.something.com",
        images: IMAGES["kandy"],

        img_filename: "kandy",
        bestTime: "April-September",
        currency: "INR",
        languages: "Ladakhi, Tibetan and Urdu",
        bespoke: [
          "Embark on a Private Hill Country Escape to Kandy and Nuwara Eliya, where serenity meets the hills. This personalized journey allows you to explore the charming landscapes, lush tea plantations, and cultural treasures of these serene destinations at your own pace.",
        ],
        curated: [
          "Discover the hill country's serene charm with fellow travelers on our curated group tour to Kandy and Nuwara Eliya. Explore lush tea plantations, cultural gems, and the tranquil beauty of these destinations while creating lasting memories in the company of like-minded enthusiasts.",
        ],
        special: [],
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        bestTime_bespoke: "Year round",
        bestTime_curated: "October-March",
        tz: "UTC+5:30",
        outro: "Unwind In Lush Green Vistas and Refreshing Mountain Air",
        attractions: "Coming Soon",
      },
      {
        carousel: {
          subDest: "Ladakh",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1>Kashmir\'s Blissful Symphony</h1></div></div>',
        },
        background_color: "#d1bdaf",
        destination: "Bentota",
        description: "Costal Bliss:",
        sub_description: "Bentota and Beyond",
        images: IMAGES["bentota"],
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        bestTime_bespoke: "Year round",
        bestTime_curated: "October-March",
        link: "https://www.something.com",
        currency: "INR",
        bespoke: [
          "Dive into the coastal wonders of Bentota, Galle, Welligama, and Yala.",
          "Embark on a Private Coastal Bliss tour and explore the wonders of Bentota, Galle, Welligama, and Yala at your own pace. This exclusive journey along the coast is tailored to your desires, offering a personalized encounter with the pristine beaches, rich history, and wildlife of these enchanting destinations.",
        ],
        curated: [
          "Join our Curated Group Tour for a Coastal Bliss adventure, diving into the wonders of Bentota, Galle, Weligama, and Yala. Explore pristine beaches, delve into the rich history, and witness the captivating wildlife of these coastal gems. Share this immersive journey with fellow enthusiasts, creating unforgettable memories together.",
        ],
        special: [],
        languages: "Ladakhi, Tibetan and Urdu",
        tz: "UTC+5:30",
        img_filename: "bentota",
        outro: "Tranquil Shores and Thrilling Coastal Adventures",
        attractions: "Coming Soon",
      },
      {
        carousel: {
          subDest: "Ladakh",
          content:
            '<div class="experiences"><div class="experiences-heading"><h1>Kashmir\'s Blissful Symphony</h1></div></div>',
        },
        background_color: "#b3c8d3",
        destination: "Colombo",
        description: "Colombo Exploration",
        sub_description: "",
        link: "https://www.something.com",
        images: IMAGES["colombo"],

        img_filename: "colombo",
        bestTime: "April-September",
        bespoke: [
          "Embark on a Private Colombo Exploration, a journey into the vibrant heart of Sri Lanka's capital city. Your exclusive tour offers a personalized encounter with the cultural tapestry of Colombo, showcasing the city's unique charm, iconic landmarks, and hidden gems.",
        ],
        curated: [
          "Experience the vibrancy of Colombo on our Curated Group Tour. Explore iconic landmarks, immerse in the lively atmosphere, and create lasting memories, all while sharing the adventure with kindred spirits.",
        ],
        special: [
          "Join us to celebrate Colombo's festive spirit as we immerse in unique cultural experiences during special holidays and events. Explore iconic landmarks and partake in the lively atmosphere, creating cherished memories with fellow travelers.",
          "Discover the joy of Sinhala and Tamil New Year celebrations, experience the vibrant Vesak festival, and witness the grandeur of the Kandy Esala Perahera. These cultural festivals offer a deeper connection to Sri Lanka's traditions and provide you with unforgettable memories.",
        ],
        pax_bespoke: "1-2 pax upwards",
        pax_curated: "10-15 pax upwards",
        pax_special: "4-6 pax upwards",
        bestTime_bespoke: "Year Round",
        bestTime_curated: "October-March",
        bestTime_special: "Seasonal",
        currency: "INR",
        languages: "Ladakhi, Tibetan and Urdu",
        outro: "Experience The Blend of Tradition and Modernity",
        tz: "UTC+5:30",
        attractions: "Coming Soon",
      },
    ],
  },
];

export default data;
