import React, { useCallback, useMemo } from "react";
import "../sass/layout/_banner.scss";
import "../sass/base/_base.scss";
import { useNavigate } from "react-router-dom";

export const Banner = ({
  style,
  id,
  width,
  heading,
  backgroundColor,
  textTransform,
  fontSize,
  renderFormButton,
  addLetterSpacing,
  headingContinued,
}) => {
  const navigate = useNavigate();

  const navToBook = useCallback(() => navigate("/book"), [navigate]);

  const renderHeading = useMemo(() => {
    if (renderFormButton) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            margin: "3rem",
          }}
        >
          <h1
            style={{
              width: width,
              letterSpacing: addLetterSpacing && "0.3rem",
              fontSize: fontSize,
              textTransform: textTransform,
              alignSelf: "center",
            }}
          >
            {heading}
            {"\n"}
            {headingContinued && headingContinued}
          </h1>

          {window.location.href.indexOf("book") === -1 && (
            <button
              className="button-pinkish"
              onClick={navToBook}
              style={{
                textAlign: "center",
                alignSelf: "center",
                boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.2)",
              }}
            >
              Book Now
            </button>
          )}
        </div>
      );
    }

    return (
      <h1
        style={{
          letterSpacing: addLetterSpacing && "0.3rem",
          width: width,
          fontSize: fontSize,
          textTransform: textTransform,
          alignSelf: "center",
        }}
      >
        {heading}
        {"\n"}
        {headingContinued && headingContinued}
      </h1>
    );
  }, [
    addLetterSpacing,
    fontSize,
    heading,
    headingContinued,
    navToBook,
    renderFormButton,
    textTransform,
    width,
  ]);

  return (
    <div
      id={id ? id : ""}
      style={{
        ...style,
        height: renderFormButton ? "inherit" : "20vh",
        backgroundColor: backgroundColor,
        alignSelf: "center",
        textAlign: "center",
      }}
      className="banner-container"
    >
      {renderHeading}
    </div>
  );
};
