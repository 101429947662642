import empowerment from "../images/gifs/Empwerment.gif";
import connection from "../images/gifs/connection_wl.gif";
import adventure from "../images/gifs/Travel.gif";
import inclusivity from "../images/gifs/Inclusivity.gif";
import responsibility from "../images/gifs/Responsibility.gif";

const data = [
  {
    icon: empowerment,
    heading: "Empowerment",
    description:
      "We believe in the strength of women supporting women. Every trip is an opportunity for personal growth and self-discovery.",
  },
  {
    icon: connection,
    heading: "Connection",
    description:
      "We foster genuine connections - with new cultures, with fellow travelers, and with ourselves.",
  },
  {
    icon: adventure,
    heading: "Adventure",
    description:
      "We embrace the thrill of the unknown and the joy of stepping out of our comfort zones.",
  },
  {
    icon: inclusivity,
    heading: "Inclusivity",
    description:
      "We celebrate diversity and create a welcoming space for all women to explore the world.",
  },
  {
    icon: responsibility,
    heading: "Responsibility",
    description:
      "We travel with purpose, respecting local communities and treading lightly on our beautiful planet.",
  },
];

export default data;
