import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import journeys from "../static/data/journeys.js";

import "../sass/layout/_product.scss";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import LazyImage from "../components/LazyImage.js";

export const Product = ({ increaseProductHeadingIndex }) => {
  const [country, setCountry] = useState(null);
  const [details, setDetails] = useState(null);
  const { destination, subDestination } = useParams();
  const [outroImage, setOutroImage] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (details) {
      setMainImage(details.images.main);
    }
  }, [details]);

  useEffect(() => {
    setCountry(journeys.find((dest) => dest.country === destination.slice(1)));

    if (country)
      setDetails(
        country.tours.find(
          (subDest) =>
            subDestination.slice(1).toLowerCase() ===
            subDest.destination.toLowerCase()
        )
      );
  }, [subDestination, destination, country]);

  const renderImageRow = useMemo(() => {
    if (details) {
      const outroImage = Object.entries(details.images).filter(
        (key) => key[0] === "outro"
      );
      setOutroImage(outroImage[0][1]);
      const images = Object.entries(details.images).filter(
        (key) => key[0] !== "outro" && key[0] !== "main"
      );

      if (images.length === 3) {
        return images.map((img, index) => (
          <img
            key={index}
            alt=""
            loading="eager"
            fetchPriority="high"
            src={img[1]}
            className="product-image"
            style={{ width: width > 600 ? "35%" : "100%" }} // Apply 35% width if there are exactly 3 images
          />
        ));
      }

      return images.map((img, index) => (
        <img
          key={index}
          alt=""
          loading="eager"
          fetchPriority="high"
          src={img[1]}
          className="product-image"
        />
      ));
    }

    return <span>Loading...</span>;
  }, [details, width]);

  const navToBookingPage = useCallback(() => {
    navigate("/book", {
      state: {
        country: country.country,
        destination: details.destination,
      },
    });
  }, [country, details, navigate]);

  const renderDetailsRow = useMemo(() => {
    if (details) {
      return (
        <>
          <ul className="list-small-text">
            <li>
              <h2 className="list-small-text-heading-coloured">
                Your Wanderluxe Journey
              </h2>
            </li>
            <li>
              <p>
                Our team of travel specialists are waiting to help you book your
                next adventure.
              </p>
            </li>
            <li>
              <button
                style={{ marginTop: "1rem" }}
                onClick={navToBookingPage}
                className="button-pinkish"
              >
                Book Now
              </button>
            </li>
            {width < 600 && (
              <>
                <li style={{ marginTop: "4rem" }}>
                  <h2 className="list-small-text-heading-coloured">
                    Download Now
                  </h2>
                </li>
                <li>
                  <button
                    style={{ marginTop: "1rem" }}
                    // onClick={navToBookingPage}
                    className="button-pinkish"
                  >
                    <a href={details.itPdf} download={details.destination} alt="">

                      ITINERARY
                    </a>
                  </button>
                </li>
              </>
            )}
          </ul>

          <ul className="list-small-text">
            Wanderluxe
            <li>
              <h2>Bespoke Private Experience</h2>
            </li>
            {details.bespoke.map((line, index) => (
              <li key={index}>
                <p>{line}</p>
              </li>
            ))}
          </ul>

          {width < 600 && (
            <ul className="list-small-text bold">
              <li>
                Number of Pax:
                <b>{details.pax_bespoke}</b>
              </li>
              <li>
                Best time to visit:
                <b>{details.bestTime_bespoke}</b>
              </li>
            </ul>
          )}

          <ul className="list-small-text">
            Wanderluxe
            <li>
              <h2>CURATED GROUP TOURS</h2>
            </li>
            {details.curated.map((line, index) => (
              <li key={index}>
                <p>{line}</p>
              </li>
            ))}
          </ul>

          {width < 600 && (
            <ul className="list-small-text bold">
              <li>
                Number of Pax:
                <b>{details.pax_curated}</b>
              </li>
              <li>
                Best time to visit:
                <b>{details.bestTime_curated}</b>
              </li>
            </ul>
          )}

          {details.special.length ? (
            <ul className="list-small-text">
              Wanderluxe
              <li>
                <h2>SPECIAL EVENT HOLIDAYS</h2>
              </li>
              {details.special.map((line, index) => (
                <li key={index}>
                  <p>{line}</p>
                </li>
              ))}
            </ul>
          ) : null}

          {details.special.length && width < 600 ? (
            <ul className="list-small-text bold">
              <li>
                Number of Pax:
                <br />
                <b>{details.pax_special}</b>
              </li>
              <li>
                Best time to visit:
                <br />
                <b>{details.bestTime_special}</b>
              </li>
            </ul>
          ) : null}
        </>
      );
    }
  }, [details, navToBookingPage, width]);

  const renderItenRow = useMemo(() => {
    if (details && width > 600) {
      return (
        <>
          <ul className="list-small-text">
            <li>
              <h2 className="list-small-text-heading-coloured">Download Now</h2>
            </li>
            <li>
              <button style={{ marginTop: "3rem" }} className="button-pinkish">
                <a href={details.itPdf} download={details.destination} alt="">
                  ITINERARY
                </a>
              </button>
            </li>
          </ul>

          <ul className="list-small-text bold">
            <li>
              Number of Pax:
              <br />
              <b>{details.pax_bespoke}</b>
            </li>
            <li>
              Best time to visit:
              <br />
              <b>{details.bestTime_bespoke}</b>
            </li>
          </ul>

          <ul className="list-small-text bold">
            <li>
              Number of Pax:
              <br />
              <b>{details.pax_bespoke}</b>
            </li>
            <li>
              Best time to visit: <br />
              <b>{details.bestTime_curated}</b>
            </li>
          </ul>

          {details.special.length ? (
            <ul className="list-small-text bold">
              <li>
                Number of Pax:
                <br />
                <b>{details.pax_special}</b>
              </li>
              <li>
                Best time to visit:
                <br />
                <b>{details.bestTime_special}</b>
              </li>
            </ul>
          ) : null}
        </>
      );
    }

    return null;
  }, [details, width]);

  return (
    country &&
    details && (
      <div className="product-main-container">
        <div className="product-container-part-1">
          <div className="product-container-part-1-left">
            <LazyImage
              imgOnly={true}
              src={mainImage}
              className="product-container-part-1-left-img"
            />
          </div>
          <div className="product-container-part-1-right">
            <h1
              // style={{ zIndex: increaseProductHeadingIndex && 105 }}
              className="product-container-part-1-right-heading"
            >
              {details.description}
            </h1>
            <h1
              // style={{ zIndex: increaseProductHeadingIndex && 105 }}
              className="product-container-part-1-right-heading"
            >
              {details.sub_description}
            </h1>
            <div className="product-container-part-1-right-row1">
              <ul>
                <h1>Country's Info</h1>
                <li>CAPITAL: {details.capital}</li>
                <li>
                  CURRENCY:{" "}
                  {details.currencyNum ? (
                    <ul>
                      {details.currency.split(",").map((el) => (
                        <li>{el}</li>
                      ))}
                    </ul>
                  ) : (
                    details.currency
                  )}
                </li>
                <li>LANGUAGE(s): {details.languages}</li>
                <li>TIME ZONE: {details.tz}</li>
              </ul>

              <ul>
                <h1>CONTACT US TO BOOK NOW</h1>
                <li>
                  Our team of travel specialists are waiting to help you book
                  your next adventure
                </li>
                <li>
                  {!(
                    window.location.href.indexOf("Bhutan") > -1 ||
                    window.location.href.indexOf("SriLanka") > -1
                  ) && (
                      <button
                        onClick={navToBookingPage}
                        className="button-pinkish"
                      >
                        BOOK NOW
                      </button>
                    )}
                </li>
              </ul>
            </div>
            <div
              className="product-container-part-1-right-row2"
              style={{ overflow: "scroll", overflowY: "scroll" }}
            >
              {!(
                window.location.href.indexOf("Bhutan") > -1 ||
                window.location.href.indexOf("SriLanka") > -1
              ) ? (
                <ul>
                  <h1>Itinerary Highlights</h1>
                  <li>
                    {Array.isArray(details.attractions)
                      ? details.attractions.map((attr) => {
                        return (
                          <ul>
                            <h1>{attr.dest}</h1>
                            <li>{attr.descrip}</li>
                          </ul>
                        );
                      })
                      : details.attractions}
                  </li>
                </ul>
              ) : (
                <h1
                  style={{ alignSelf: "flex-end", textTransform: "uppercase" }}
                >
                  Coming Soon
                </h1>
              )}
            </div>
          </div>
        </div>
        <div className="product-container-part-2">
          <div className="product-container-part-2-row1">{renderImageRow}</div>
          <div className="product-container-part-2-row2">
            {renderDetailsRow}
          </div>
          <div className="product-container-part-2-row3">{renderItenRow}</div>
        </div>

        <div className="product-container-outro">
          <LazyImage
            component={<img src={outroImage} alt="" fetchPriority="low" />}
          />
          <div className="product-container-outro-heading">
            <h2>Discover Your Wanderluxe </h2>
            <h2>Experiences Here</h2>
          </div>
        </div>
      </div>
    )
  );
};
