import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import "../sass/main.scss";
import axios from "axios";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import { SuccessModal } from "./SuccessModal.js";

export const BookNow = () => {
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);

  const location = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { width } = useWindowDimensions();

  const country = useMemo(
    () => (location.state ? location.state.country : ""),
    [location.state]
  );
  const destination = useMemo(
    () => (location.state ? location.state.destination : ""),
    [location.state]
  );

  const { handleSubmit, register, watch, getValues, setValue } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      country: country,
      destination: destination,
      departDate: "",
      duration: "",
      numberTravellers: 0,
      numberRooms: 0,
      budget: "",
      email: "",
      other: "",
      checkTnc: false,
    },
  });

  const countriesToCityMap = {
    India: ["Amritsar", "Delhi, Agra & Jaipur", "Kerala", "Kashmir", "Ladakh"],
    // Bhutan: ["Paro", "Punakha", "Thimphu"],
    // "Sri Lanka": ["Bentota", "Colombo", "Kandy and Nuwara"],
  };

  const onSubmit = useCallback(
    async (data) => {
      if (data && isChecked) {
        const response = await axios
          .post("https://wander-server.vercel.app/api/bookNow", data)
          .catch((e) => console.log("There was an error while booking..", e));

        if (response.data.success) {
          setOpenModal(true);
        }
      }
    },
    [isChecked]
  );

  const cityOptions = useMemo(() => {
    return watch("country") ? countriesToCityMap[getValues("country")] : [];
  }, [watch("country"), getValues("country")]);

  const increaseTravellers = useCallback(
    () => setValue("numberTravellers", watch("numberTravellers") + 1),
    [watch, setValue]
  );
  const decreaseTravellers = useCallback(
    () => setValue("numberTravellers", watch("numberTravellers") - 1),
    [watch, setValue]
  );

  const increaseRooms = useCallback(
    () => setValue("numberRooms", watch("numberRooms") + 1),
    [watch, setValue]
  );
  const decreaseRooms = useCallback(
    () => setValue("numberRooms", watch("numberRooms") - 1),
    [watch, setValue]
  );

  return (
    <div className="book-now-container">
      <form onSubmit={handleSubmit(onSubmit)} className="container-part-1">
        <SuccessModal openModal={openModal} setOpenModal={setOpenModal} />
        <div className="inputs-row-4">
          <div key="firstName" className="input-container">
            <p>First Name</p>

            <input {...register("firstName", { required: true })} />
          </div>
          <div key="lastName" className="input-container">
            <p>Last Name</p>

            <input {...register("lastName", { required: true })} />
          </div>
          <div key={"date"} className="input-container">
            <p>Departure Date</p>
            <input
              type="date"
              {...register("departDate", { required: true })}
              placeholder="Departure Date"
            />
          </div>

          <div key={"stay"} className="input-container">
            <p>Length of Stay</p>
            <input {...register("duration", { required: true })} />
          </div>
        </div>

        <div className="inputs-row-4">
          <div key="country" className="input-container">
            <p>Country</p>
            <select
              className="input-container-select"
              {...register("country", { required: true })}
            >
              {Object.keys(countriesToCityMap).map((country) => (
                <option
                  className="input-container-select-option"
                  value={country}
                >
                  {country}
                </option>
              ))}
            </select>
          </div>

          <div key={"destination"} className="input-container">
            <p>Destination</p>
            <select
              className="input-container-select"
              {...register("destination", { required: true })}
            >
              {cityOptions.map((country) => (
                <option
                  className="input-container-select-option"
                  value={country}
                >
                  {country}
                </option>
              ))}
            </select>
          </div>

          <div key={"numberTravellers"} className="counter-field">
            <p>No. of Travellers</p>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button className="button-round" onClick={increaseTravellers}>
                +
              </button>
              <p
                style={{ margin: "auto 0px" }}
                {...register("numberTravellers", {
                  required: true,
                  min: 0,
                  minLength: 0,
                })}
              >
                {getValues("numberTravellers")}
              </p>
              <button
                className="button-round"
                disabled={watch("numberTravellers") === 0}
                onClick={decreaseTravellers}
              >
                -
              </button>
            </div>
          </div>

          <div key={"room"} className="counter-field">
            <p>No. of Rooms</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button onClick={increaseRooms} className="button-round">
                +
              </button>
              <p
                style={{ margin: "auto 0px" }}
                {...register("numberRooms", {
                  required: true,
                  min: 0,
                  minLength: 0,
                })}
              >
                {getValues("numberRooms")}
              </p>
              <button
                disabled={watch("numberRooms") === 0}
                className="button-round"
                onClick={decreaseRooms}
              >
                -
              </button>
            </div>
          </div>
        </div>

        <div className="inputs-row-2">
          <div key={"budget"} className="input-container">
            <p>Budget</p>
            <select
              className="input-container-select"
              {...register("budget", { required: true })}
            >
              <option
                className="input-container-select-option"
                value="S$ 2000/5000 per person"
              >
                S$ 2000/5000 per person
              </option>
              <option
                className="input-container-select-option"
                value="S$ 5000/10000 per person"
              >
                S$ 5000/10000 per person
              </option>
              <option
                className="input-container-select-option"
                value="S$ 10000/15000 per person"
              >
                S$ 10000/15000 per person
              </option>
              <option
                className="input-container-select-option"
                value="Over S$15000 per person"
              >
                Over S$15000 per persons
              </option>
            </select>
          </div>
          <div key="email" className="input-container">
            <p>Email</p>
            <input {...register("email", { required: true })} />
          </div>
        </div>

        <div key="name" className="input-container" style={{ width: "100%" }}>
          <p>Other Requests</p>
          <input {...register("other", { required: false })} />
        </div>
      </form>

      <div className="container-part-2">
        <div className="checkbox-row">
          <input
            onClick={(e) => setIsChecked(e.target.checked)}
            type="checkbox"
            value={true}
          />
          <p>
            I agree to my personal information being used in accordance to
            Wanderluxe privacy policy
          </p>
        </div>
        <div className="revert-row">
          <p>We will get back to you within 48 hours.</p>
        </div>
      </div>
      <button
        onClick={handleSubmit(onSubmit)}
        style={{ margin: width < 500 && "2rem" }}
        className="button-greyish"
      >
        Submit
      </button>
      <div className="info-send-container">
        <div className="info-row">
          <div className="info-row-element">
            <h2>Email</h2>
            <p>info@wanderluxe.sg</p>
          </div>
          {/* <div className="info-row-element">
            <h2>Call</h2>
            <p>+91 12456789089</p>
          </div>
          <div className="info-row-element">
            <h2>Whatsapp</h2>
            <p>+91 12456789089</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
