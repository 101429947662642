import React, { useEffect, useMemo } from "react";
import useEmblaCarousel from "embla-carousel-react";
import AutoPlay from "embla-carousel-autoplay";
import { DotButton, useDotButton } from "./DotButton.js";

export const Carousel = ({ addMargin, data, imgs }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false }, [AutoPlay()]);
  const { onDotButtonClick, scrollSnaps, selectedIndex } =
    useDotButton(emblaApi);

  console.log("Data : ", data);

  useEffect(() => {
    if (emblaApi) console.log(emblaApi.slideNodes());
  }, [emblaApi]);

  const renderSlides = useMemo(() => {
    if (!data) return null;
    return data.map((slide, index) => {
      const backgroundImage = slide.subDest
        ? imgs[slide.subDest.toLowerCase()]
        : slide.img;
      const content =
        typeof slide.content === "object" ? (
          slide.content
        ) : (
          <div
            style={{ position: "relative" }}
            dangerouslySetInnerHTML={{ __html: slide.content }}
          />
        );

      return (
        <div
          key={index}
          className="embla__slide"
          style={{
            height: "80vh",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: "3rem",
          }}
        >
          {content}
        </div>
      );
    });
  }, [data, imgs]);

  if (!data) return null;

  return (
    <section className="embla" style={{ marginTop: addMargin ? "3rem" : 0 }}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">{renderSlides}</div>
        <div className="embla__controls">
          {scrollSnaps.map((_, index) => (
            <div className="embla__dots">
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={"embla__dot".concat(
                  index === selectedIndex ? " embla__dot--selected" : ""
                )}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
