import React from "react";
import "../sass/main.scss";
import Modal from "react-responsive-modal";
import closeIconSmall from "../static/images/icons/closeIconSmall.png";
import wanderLogo from "../static/images/WL_LOGO.svg";
export const SuccessModal = ({ setOpenModal, openModal }) => {
  const onClose = () => setOpenModal(false);

  return (
    <Modal
      closeOnEsc
      styles={{
        modalContainer: {
          position: "relative",
        },
        modal: {
          backgroundColor: "white",
          marginTop: "10%",
        },
        closeButton: {
          position: "absolute",
          top: 10,
          right: 10,
        },
      }}
      open={openModal}
      onClose={() => onClose()}
      closeIcon={
        <img
          style={{}}
          src={closeIconSmall}
          alt="Close"
          height={35}
          width={35}
        />
      }
    >
      <div className="confirm-box-text">
        Thank you for your query, <br /> we will reach out to you shortly.
        <img
          alt="wanderluxe"
          style={{ width: "60%", marginTop: "4rem" }}
          src={wanderLogo}
        />
      </div>
    </Modal>
  );
};
